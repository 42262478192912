<template>
  <div>
    <!-- 封面 -->
    <div class="backbox">
      <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" :src="series.banner" alt="">
      <div class="kuanzz"></div>
      <div class="stylebox flex-center">
        <div>{{ series.style }}</div>
      </div>
      <div class="titleboxs">{{ series.name }}</div>
    </div>
    <!-- 返回 -->
    <div class="goback flex-space-between">
      <div class="leftbox flex">
        <div class="imgbox"><img src="@/assets/page/fangz.png" alt="" /></div>
        <div class="title">家具</div>

        <div style="margin: 0 4px">-</div>
        <div class="name">{{ series.name }}</div>
      </div>
      <div class="rightbox flex" @click="gobackclick">
        <div class="fhbox">返回</div>
        <div class="imgbox"><img src="@/assets/page/fh.png" alt="" /></div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="titlebox flex-space-between">
      <div class="imgbox">
        <img src="@/assets/page/cpxl.png" alt="" />
      </div>
      <div class="rigthbox flex-space-between">
        <el-select v-model="value" placeholder="查看系列" @change="xlchenge">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
    </div>
    <!-- 列表 -->
    <div class="Exhibition" v-if="listarr.length!=0">
      <div class="listbox">
        <div class="itembox" v-for="(item, index) in listarr" :key="index">
          <div class="itemimg">
            <el-image
              @click="imageClick(item)"
              ref="myImg"
              :z-index="3000"
              :src="item.image"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
          <div class="itemname">{{ item.name }}</div>
          <div class="quanfd">
            <img  src="@/assets/page/quan.png" alt="" />
          </div>
        </div>
      </div>
    </div>
      <!-- 缺失页 -->
      <div class="maxqsbox" v-else>
      <div class="qsbox">
        <img src="@/assets/page/qst.png" alt="">
        <div>暂无数据</div>
      </div>
    </div>
    
    <!-- 文字描述 -->
    <div class="introduction">
      <div class="text1">
        {{ series.memo }}
      </div>
      <div class="text2">
        {{ series.en_memo }}
      </div>
    </div>
    <!-- 分页 -->
    <div style="margin: 40px auto;height: 70px;"  class="fnebox">
      <el-pagination
          background
          class="fenye"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          layout="prev, pager, next"
          :page-size="page.per_page"
          :total="page.total"
        >
       </el-pagination>
    </div>
    <!-- 上一页下一页 -->
    <div class="previous flex">
      <div class="left" v-if="last" @mouseenter="leftMove" @mouseleave="leftMove">
        <div class="kuanzz"></div>
        <!-- 原来的文字 -->
        <div class="textinfo" v-show="!info.leftshow">
          <div class="mintext">{{ last.style }}</div>
          <div class="consttext">{{ last.name }}</div>
        </div>
        <!-- 底部照片 -->
        <img
          class="yimgs"
          :src=" last.banner"
          alt=""
        />
        <!-- 遮罩 -->
        <!-- v-show="info.leftshow" -->
        <div class="poabox" @click="lastclick(1)">
          <div class="fbufel">{{ last.style }}</div>
          <div class="flex buttombox">
            <div class="butimgbox"><img src="@/assets/page/djt.png" alt="" /></div>
            <div class="miaoshu">{{ last.name }}</div>
          </div>
        </div>
      </div>
      <div class="right" v-if="next" @mouseenter="rightmove" @mouseleave="rightmove">
        <div class="kuanzz"></div>
        <div class="textinfo" v-show="!info.rightshow">
          <div class="mintext">{{ next.style }}</div>
          <div class="consttext">{{ next.name }}</div>
        </div>

        <img
          class="yimgs"
          :src=" next.banner"
          alt=""
        />
        <!-- 遮罩 -->
        <!-- v-show="info.rightshow" -->
        <div class="poabox" @click="lastclick(2)">
          <div class="fbufels">{{ next.style }}</div>
          <div class="flex buttombox">
            <div class="miaoshu">{{ next.name }}</div>
            <div class="butimgbox" style="margin-left: 6px"><img src="@/assets/page/yiu.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {homeProduct,homeSeriesList} from '../../service/request/homepage'
export default {
  data() {
    return {
      // 下拉框选项
      options: [],
      series: {},//头部数据
      listarr: [],//内容
      value: '', //下拉框的选择
      id:null,
      info: {
        leftshow: false,
        rightshow: false
      },
     
      srcList: [],//预览图片
      // 分页
      page: {
        page:1,
        last_page: 0,
        per_page: 8,
        total: 100,
      },
      // 上一页
      last: {},
      // 下一页
      next:{}
    }
  },
  components: {},
  mounted() {
    this.id=this.$route.query.id
    // 初始数据
    this.homeProduct()
    // 下拉
    this.homeSeriesList()
  },
  methods: {
    // 初始数据
    homeProduct() {
      homeProduct(
        {
          id: this.id,
          page:this.page.page
        }
      ).then(res => {
        console.log('item', res);
        this.page.last_page = res.msg.last_page
        this.page.per_page = res.msg.per_page
        this.page.total = res.msg.total
        this.series = res.msg.series
        this.listarr = res.msg.data
        this.last=res.msg.last
        this.next=res.msg.next
      })
    },
    // 下拉
    homeSeriesList() {
      homeSeriesList(
       {id: this.$route.query.id}
      ).then(res => {
        // console.log(res, '下拉');
        this.options=res.msg
      })
    },
    // 下拉chenge
    xlchenge(e) {
      console.log(e);
      this.id = e
      this.homeProduct()
    },
    // 分页
    handleCurrentChange(val) {
      this.page.page = val;
      this.homeProduct()
      window.scrollTo(0, 0)
    },
    // 上一页下一页
    lastclick(type) {
      if (type == 1) {
        this.id = this.last.id
        this.homeProduct()
      } else {
        this.id = this.next.id
        this.homeProduct()
      }
    },
    // 返回
    gobackclick() {
      this.$router.go(-1)
    },
    // 鼠标移入
    leftMove() {
      this.info.leftshow = !this.info.leftshow
    },
    // 右边
    rightmove() {
      this.info.rightshow = !this.info.rightshow
    },
    doPriveImg() {
      console.log(this.$refs.myImg)
      this.$refs.myImg.showViewer = true
    },
    imageClick(item) {
      this.$refs.myImg.showViewer = true
      this.srcList = []
      this.srcList.push(item.image)
    }
  }
}
</script>
<style scoped lang="less">
.backbox {
  width: 100%;
  height: 610px;
  // background: url('../../assets/page/bjt5.png') no-repeat;
  // background-size: cover;
  overflow: hidden;
  position: relative;
  .kuanzz {
    position: absolute;
    width: 100%;
    height: 610px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4); //遮罩层
  }
  .stylebox {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 234px;
    left: 50%;
    transform: translate(-50%);
    div {
      padding: 5px 30px;
      border: 1px solid #fff;
      // border-radius: 5px;
    }
  }
  .titleboxs {
    color: #fff;
    font-size: 50px;
    position: absolute;
    top: 314px;
    left: 50%;
    transform: translate(-50%);
  }
}
.goback {
  // position: relative;
  //   padding-top: 188px;
  margin: 0 85px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftbox {
    .imgbox {
      width: 19px;
      height: 16px;
      line-height: 10px;
      margin-right: 9px;
      img {
        width: 19px;
        height: 16px;
      }
    }
    .title,
    .series {
      font-size: 16px;
      color: #999999;
    }
    .name {
      color: #3e907c;
      font-size: 16px;
    }
  }
  .rightbox {
    .fhbox {
      font-size: 16px;
      color: #3e907c;
      margin-right: 6px;
    }
    .imgbox {
      width: 20px;
      height: 14px;
      line-height: 10px;
      img {
        width: 20px;
        height: 14px;
      }
    }
  }
}
.titlebox {
  margin: 0 85px;
  .imgbox {
    width: 295px;
    height: 56px;
    img {
      width: 295px;
      height: 56px;
    }
  }
  .rigthbox {
    width: 175px;
    height: 50px;
    border-radius: 1px;
    background-color: #3e907c;
    .el-select {
      width: 175px;
      height: 50px;
      background-color: #3e907c;
    }

    /* .seeinfo {
      width: 89px;
      line-height: 50px;
      font-size: 18px;
      margin-left: 15px;
      color: #fff;
    }
    .rightimg {
      width: 15px;
      height: 9px;
      line-height: 45px;
      margin-right: 14px;
      img {
        width: 15px;
        height: 9px;
      }
    } */
  }
}
.Exhibition {
  margin: 30px 85px 0;
  .listbox {
    display: flex;
    flex-wrap: wrap;
    .itembox {
      margin-right: 13px;
      margin-top: 13px;
      width: 860px;
      height: 578px;
      border-radius: 6px;
      position: relative;
      .itemimg {
        width: 860px;
        height: 578px;
        border-radius: 6px;
        .el-image{
          width: 860px;
          height: 578px
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
        .el-image {
          border-radius: 6px;
        }
      }
      .itemname {
        position: absolute;
        color: #fff;
        font-size: 28px;
        bottom: 40px;
        left: 40px;
      }
      .quanfd {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 0;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .itembox:nth-child(2n) {
      margin-right: 0;
    }
  }
}
.introduction {
  // margin: 60px 0;
  margin: 60px 80px;
  .introduction {
    color: #333333;
    font-size: 16px;
    .text2 {
      margin-top: 40px;
    }
  }
}
.previous {
  .left,
  .right {
    width: 950px;
    height: 330px;
    transition: all 0.6s;
    position: relative;
    .kuanzz {
      width: 950px;
      height: 330px;
      transition: all 0.6s;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2); //遮罩层
    }
    .textinfo {
      position: absolute;
      top: 131px;
      left: 391px;
      color: #fff;
      .mintext {
        font-size: 18px;
      }
      .consttext {
        margin-top: 5px;
        font-size: 32px;
      }
    }

    .yimgs {
      width: 950px;
      height: 330px;
    }
    .poabox {
      position: absolute;
      top: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      transition: all 0.6s;
      width: 950px;
      height: 330px;
      background: rgba(0, 0, 0, 0.39);
      .fbufel {
        margin-top: 131px;
        margin-left: 441px;
        color: #fff;
        font-size: 18px;
      }
      .fbufels {
        margin-top: 131px;
        margin-left: 319px;
        color: #fff;
        font-size: 18px;
      }
      .buttombox {
        margin-top: 8px;
        margin-left: 316px;
        .butimgbox {
          width: 120px;
          height: 15px;
          img {
            width: 120px;
            height: 15px;
          }
        }
        .miaoshu {
          margin-left: 5px;
          font-size: 32px;
          color: #fff;
        }
      }
    }
  }
  .left:hover .poabox {
    opacity: 1;
    transition: all 0.6s;
  }
  .left:hover .kuanzz {
    opacity: 0;
    transition: all 0.6s;
  }

  .right:hover .poabox {
    opacity: 1;
    transition: all 0.6s;
  }
}
.fnebox{
  position: relative;
  .el-pagination{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  }
}
.maxqsbox{
  width: 100%;
  display: flex;
  justify-content: center;
  .qsbox{
   margin: 200px auto 0;
   min-height: 500px;
   text-align: center;
  img{
    width: 112px;
    height: 112px;
  }
  div{
    margin-top: 10px;
    color: #999;
  }

}
}
</style>
